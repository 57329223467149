.switch-to-app {
  background-color: white;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
  box-shadow: 3px 0px 6px #00000029;
  margin: 20px 0 0 0;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 99;

  .btn-bg {
    bottom: 7px;
    right: 15px;
  }
  button.btn.btn-link {
    background-color: white;
    border-radius: 30px;
    padding: 0;
    border: 0;
  }

  .title {
    font-size: 15px;
    font-weight: 900;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .text {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 22px;
    text-transform: capitalize;
    text-align: left;
  }
  button.btn.btn-danger {
    background-color: #cf0a10;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 40px;
  }
  @media only screen and (max-width: 767px) {
    display: block !important;
  }
}
