.box-title {
  font-size: 20px;
  margin-bottom: 33px;
}
.title-has-btn .box-title {
  float: left;
}
.title-has-btn .see-all-btn {
  float: right;
  @media (min-width: 768px) {
    margin-top: 5px;
    padding-right: 14px;
    background: url("/img/icons/see-all-arrow-lg.svg") no-repeat center right;
  }
}
.title-has-btn {
  margin-bottom: 30px;
  position: relative;
  padding-right: 20px;
  .box-title {
    margin-bottom: 0;
  }
  .see-all-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
