.error-page .h2 {
  color: #000;
  margin-bottom: 20px;
}

.error-page p {
  color: #b2b9bf;
}

.error-page .btn {
  margin-top: 20px;
}

.error-page img {
  margin-bottom: 60px;
}
