$navbar_height: 80px;
$offers-map-height: calc(100% - 80px);

.navbar-fixed {
  z-index: 99;
  width: 100%;
  @media (min-width: 768px) {
    position: fixed;
  }
}
.navbar {
  height: $navbar_height;
  // margin-bottom: 40px;
  box-shadow: 0px 8px 16px #a1a4b84d;
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    margin-right: 0;
  }
}

main.main-container {
  @media (min-width: 768px) {
    padding-top: $navbar_height;
  }
}

.offers-list-map {
  height: $offers-map-height;
}
