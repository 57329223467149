.row {
  &.row-gap-0 {
    margin-left: -0px;
    margin-right: -0px;
    div[class^="col-"] {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  &.row-gap-10 {
    margin-left: -10px;
    margin-right: -10px;
    div[class^="col-"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
