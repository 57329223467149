.offer-list {
}

.offer-list {
  &.list-view {
    .card figure {
      width: 155px;
      float: left;
      margin-right: 20px;
    }
    .offercard {
      margin-bottom: 24px;
      figure.background-cover {
        margin-bottom: 0;
      }
    }
  }
}
