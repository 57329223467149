.merchant-details-page {
  .merchant-details {
    // padding-top: 20px;
  }
  .top-bg {
    padding-bottom: 20px;
    @media (min-width: 768px) {
      padding-top: 50px;
    }
  }
}
.merchant-details {
  padding-bottom: 36px;
  img {
    // margin-bottom: 10px;
  }
  h2 {
    font-size: 23px;
    font-weight: 900;
    margin-bottom: 15px;
  }
  span {
    // font-size: 18px;
  }
  .merchant-contact {
    // font-size: 18px;
    // font-weight: 600;
    margin-bottom: 20px;
  }
}
