@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap");

body {
  // font-family: "Bebas Neue", cursive; //becomes very small font size
  font-family: "Montserrat", Arial, sans-serif;
  @media (min-width: 768px) {
  }
}

.offercard + .no-records-found {
  display: none;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

:root {
  --dark: #292a2c;
  --primary: #d01118;
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  overflow-x: hidden;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  color: var(--primary);
  &:hover {
    color: var(--primary);
  }
}

h2 {
  // font-family: "Bebas Neue", cursive;
}

img,
button,
:focus,
input:focus {
  outline: 0;
}

.border-radius-default {
  border-radius: 8px;
}

.background-cover {
  background-color: var(--dark);
  //added because the image from server are not accessible

  background-size: cover;
  background-repeat: no-repeat;
}
.background-center-center {
  background-position: center center;
}

.h-0 {
  height: 0;
}

.white-space-pre-line {
  white-space: pre-line;
}

@import "./components/btn";
@import "./components/row";
@import "./components/text";
@import "./components/img";
@import "./components/top-bg";
@import "./components/separator";
@import "./components/title-group";
@import "./components/loader";
@import "./components/toastify";

@import "./components/slick-slider";
@import "./components/offer-card";
@import "./components/opening-hours";
@import "./components/offers-details";
@import "./components/offers-list-page";
@import "./components/navbar";
@import "./components/categories";
@import "./components/feature-offer-slider";
@import "./components/feature-slider";
@import "./components/home-offers";
@import "./components/merchant-details";
@import "./components/offer-list";
@import "./components/offer-list-page";
@import "./components/info-window";
@import "./components/homepage-featured-offer";
@import "./components/related-offer";
@import "./components/accordion";
