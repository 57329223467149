.footer {
  margin-top: 40px; //consider homepage
  color: #7d8597;
  padding-bottom: 50px;
  a {
    color: #7d8597;
  }
}
.logo-footer img {
  margin-bottom: 40px;
}
.compressed-footer .logo-footer img {
  margin-bottom: 16px;
}
.visibility-hidden {
  visibility: hidden;
}

.footer-brand {
  margin-right: 20px;
}
@media (min-width: 321px) {
  img.company {
    // margin-left: 20px;
  }
}
@media (min-width: 992px) {
  img.company {
    // margin-left: 0px;
  }
}

.footer li {
  list-style: none;
  color: gray;
  margin-bottom: 6px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .footer-nav {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .footer-nav {
    margin-top: 0px;
  }
}

.footer-nav h6 {
  color: #334565;
  font-weight: 900;
  margin-bottom: 15px;
  font-size: 18px;
}
.footer-nav .list-list {
  margin-bottom: 35px;
}

//--------copyright section-----------
.copy-right-footer {
  padding-top: 24px;
  border-top: 1px solid rgba(51, 69, 101, 0.1);
}
.copy-link {
  margin-bottom: 24px;
  @media (min-width: 576px) {
    // margin-bottom: 0;
  }
}
.copy-link p {
  font-size: 14px;
}
.copy-right {
  border-top: 0.5px solid grey;
  padding-top: 15px;
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  .copy-right {
    margin-bottom: 86px;
  }
}
.copy-link {
  color: grey;
}
.media-background {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  float: left;
  color: var(--white);
}
.footer a.media-background {
  color: var(--white);
}
.facebook {
  background: #3d5a96;
  margin-right: 17px;
}
.twitter {
  background: #2aa3ef;
  margin-right: 17px;
}
.instagram {
  background: transparent
    linear-gradient(180deg, #7024c4 0%, #c21975 42%, #c74c4d 70%, #e09b3d 100%);
  margin-right: 17px;
}
.linked-in {
  background: #007ab7;
}

@media (min-width: 576px) {
  .copy-link {
    float: left;
  }
  .copyright-media {
    float: right;
  }
}
.footer-nav {
  .list-list {
    @media (min-width: 360px) {
      float: left;
      width: 50%;
      .footer-list {
        min-height: 122px;
      }
    }
    @media (min-width: 768px) {
      padding-right: 21px;
      width: 25%;
    }
    @media (min-width: 1200px) {
      padding-right: 24px;
    }
  }
}
.compressed-footer {
  @media (min-width: 768px) {
    .list-list {
      width: 50%;
    }
    .copy-link,
    .copyright-media {
      float: none;
    }
  }
  .footer-nav {
    @media (min-width: 992px) {
      margin-top: 30px;
    }
    @media (min-width: 1200px) {
      margin-top: 0px;

      .copy-link,
      .copyright-media {
        float: left;
      }
    }
  }

  @media (min-width: 1200px) {
    .copy-link {
      float: left;
    }
    .copyright-media {
      float: right;
    }
  }
}
