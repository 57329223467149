.top-bg {
  position: relative;
  padding-top: 30px;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    padding-top: 30px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 30px;
    right: 0;
    background: rgb(245, 246, 250);
    z-index: -1;
  }
}
