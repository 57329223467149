.caption-title {
  font-weight: 500;
}

.feature-slider {
  &.slick-slider {
    @media (max-width: 767px) {
      padding-left: 6px;
      padding-right: 6px;
      .slick-list {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .banner-caption {
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
    small {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .caption-title {
    font-size: 22px;
    @media (min-width: 768px) {
      font-size: 30px;
    }
  }

  .feature-offer {
    background: none;
    padding-right: 10px;
    padding-left: 10px;
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .card,
  figure {
    border-radius: 8px;
  }
  .offer-text {
    border-radius: 8px;
  }
}
