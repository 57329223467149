.feature-offer .card {
  background: white;
}
.feature-offer {
  border-radius: 8px;
  // margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 481px) {
  .feature-offer {
    margin-left: 10px;
    margin-right: 10px;
  }
}
