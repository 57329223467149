.dropdown-menu {
  padding: 0;
}
.dropdown-item {
  padding: 9px 16px;
}
.dropdown-divider {
  display: none;
}

.offers-list-page {
  .filters {
    .btn {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  .offer-list.grid-view {
    display: flex;
    flex-wrap: wrap;
  }
  .grid-view .offercard {
    // float: left;
    width: 50%;
    flex: 0 0 50%;
    @media (min-width: 1440px) {
      width: 33.33%;
      flex: 0 0 33.33%;
    }
  }

  .offers-list-map {
    @media (min-width: 768px) {
      position: fixed;
      width: 50%;
    }
  }
  .offers-list-column {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .list-view .card {
    display: block;
  }
}
.related-offer {
  figure {
    border-radius: 8px;
  }
}
.nearbyoffer-list {
  figure {
    // margin-right: 20px;
    border-radius: 8px;
  }
  .nearby-margintop {
    // margin-top: 40px;
  }

  .box-title {
    font-size: 20px;
    margin-bottom: 20px;
    // margin-left: 23px;
    // margin-top: 40px;
  }
  .button-group .btn {
    margin-right: 16px;
    margin-bottom: 40px;
  }
  @media (min-width: 320px) {
    .button-group .btn {
      margin-bottom: 14px;
    }
  }

  .btn-primary {
    margin-bottom: 25px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .offer-list.grid-view {
    margin-left: -10px;
    margin-right: -10px;
    .offercard {
      padding-right: 10px;
      padding-left: 10px;
      margin-bottom: 32px;
    }
  }
}

.filters {
  margin-bottom: 20px;
}
.offer-list.list-view {
  .discount {
    display: none;
  }
  .offercard-details {
    margin-top: 0px;
  }
}
