figure.background-cover {
  margin-bottom: 0;
}
.offercard figure.background-cover {
  margin-bottom: 16px;
}

.offercard {
  .discount.tier-enabled {
    padding-top: 9px;
  }
  .discount span.up-to {
    display: block;
    font-size: 10px;
    position: absolute;
    top: 3px;
  }
  .card {
    border: none;
    border-radius: 0;
  }
  img {
    margin-bottom: 14px;
    border-radius: 8px;
  }
  .offercard-details {
    text-align: left;
  }

  h3 {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  .sub-merchant {
    margin-bottom: 4px;
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    @media (min-width: 768px) {
      font-size: 15px;
    }
  }
  .distance {
    color: rgb(182, 171, 171);
    line-height: 19px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
