// ARROWS: true
.slick-prev:before,
.slick-next:before {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  content: "" !important;
  opacity: 1 !important;
  height: 100%;
  width: 100%;
  display: block;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25 !important;
}

// For offers sliders
.slick-prev,
.slick-next {
  height: 30px !important;
  z-index: 9;
}
.slick-prev,
.slick-next {
  top: -40px !important;
}
.slick-prev {
  left: auto !important;
  right: 30px !important;
}
.slick-next {
  right: 0 !important;
}
.slick-prev:before {
  background-image: url("/img/icons/chevron-left.svg");
}
.slick-next:before {
  background: url("/img/icons/chevron-right.svg");
}

.feature-slider {
  .slick-prev,
  .slick-next {
    width: 40px !important;
    height: 50px !important;
  }
  .slick-prev,
  .slick-next {
    /* height: 100% !important; */
    /* background: rgba(0, 0, 0, 0.1); */
  }
  .slick-prev,
  .slick-next {
    top: 50% !important;
  }
  .slick-prev {
    left: 20px !important;
    @media (min-width: 768px) {
      left: 0 !important;
    }
  }
  .slick-next {
    right: 20px !important;
    @media (min-width: 768px) {
      right: 0 !important;
    }
  }
  .slick-prev:before {
    background-image: url("/img/icons/chevron-left-white.svg");
  }
  .slick-next:before {
    background: url("/img/icons/chevron-right-white.svg");
  }
}

.not-slick-slider,
.slick-slider {
  margin-bottom: 40px;
  @media (min-width: 768px) {
    margin-bottom: 50px;
  }
}
@media (min-width: 481px) {
  .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.slick-dots li button:before {
  color: var(--primary) !important;
  // opacity: 0.4 !important; //commented because of error in safari
}
.slick-dots li.slick-active button:before {
  color: var(--primary);
  // opacity: 1 !important; //commented because of error in safari
}
.slick-dots {
  position: absolute;
  text-align: left !important;
  // right: 77px;
  margin-bottom: 10px;
}
.slick-dots,
.slick-dots li button:before {
  position: inherit !important;
  bottom: 0 !important;
}

.slick-dots li,
.slick-dots li button,
.slick-dots li button:before {
  height: 6px !important;
  width: 6px !important;
}
.slick-dots {
  // padding: 0 10px !important;
}
