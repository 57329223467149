// @import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
h2.box-title {
  // font-size: 28px;
  font-weight: 900;
  text-align: left;
  // margin-bottom: 30px;
}

.title-has-btn a {
  text-decoration: none;
}

.title-has-btn span {
  color: black;
  font-size: 15px;
  font-weight: 900;
}
.slick-list {
  margin-left: -10px;
  margin-right: -10px;
}

.card-popular-categories {
  height: 138px;
}
.not-slick-slider .card-popular-categories {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
}
.categories {
  height: 100%;
  // display: flex;
  align-items: center;
  // padding-right: 100px;
}

.categories a {
  // display: inline-flex;
}
.card-popular-categories {
  background-color: #f0f0f0;
  padding-right: 16px;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  .brand {
    margin-top: 0;
    // margin-left: 20px;
    // margin-top: 20px;
    padding-left: 20px;
    padding-right: 100px;

    h4 {
      line-height: 28px;
      margin-bottom: 0px;
      font-weight: 700;
      color: black;
      text-transform: uppercase;
      text-overflow: ellipsis;
      // height: 56px;
      word-wrap: break-word;
      font-size: 21px;
      @media (min-width: 576px) {
        font-size: 18px;
      }
      @media (min-width: 768px) {
        font-size: 21px;
      }
    }
    small {
      font-size: 11px;
      font-weight: bold;
      line-height: 14px;
      color: grey;
    }
  }
  .brand-items img {
    // height: 119px;
    // margin: -54px 11px 10px 0;
  }
}
.categories {
  // background-image: url(/img/photo-2.png);
  background-position: right center;
  // background-size: 94px;
  background-repeat: no-repeat;
}

.categories a {
  text-decoration: none;
}

// UPDATED
.card-popular-categories {
  padding-right: 0;
  position: relative;

  > a,
  .brand-items {
    height: 100%;
  }

  .brand-items img {
    height: 100%;
  }

  .brand {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
  }
}

.not-slick-slider .card-popular-categories {
  margin-bottom: 10px;
}
