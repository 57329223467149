.not-slick-slider,
.slick-slider {
  .offercard {
    h3 {
      color: black;
    }
    figure {
      border-radius: 8px;
    }
  }
}
.slick-slider {
  .slick-list {
    margin-left: -10px;
    margin-right: -10px;
  }
  // offers css
  // h2.box-title {
  //     margin-bottom: 30px;
  // }
  .offercard {
    margin-left: 10px;
    margin-right: 10px;
  }
}
