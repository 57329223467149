$btn-shadow: 0px 4px 8px #a1a4b859;

@mixin btn-color {
  background: var(--white) 0% 0% no-repeat padding-box;
  color: var(--dark);
}
@mixin btn-hover {
  background: var(--dark);
  color: var(--white);
}
@mixin btn-shadow {
  box-shadow: $btn-shadow !important;
}

.btn {
  padding: 7px 20px;
}

.btn-secondary {
  @include btn-color;
  @include btn-shadow;
  border: 0;
  border-radius: 6px;
  font-weight: bold;
  &:hover,
  &:not(:disabled):not(.disabled).active,
  &:active,
  &:not(:disabled):not(.disabled):active {
    @include btn-hover;
    @include btn-shadow;
    svg {
      fill: var(--white);
    }
  }
  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    // opennow active huda - click garda active hatauda pani active vayeko jasto dekhiyo - focus le...
    @include btn-color;
  }
}

.btn-secondary.dropdown-toggle {
  &::after {
    display: none;
  }
  svg {
    margin-left: 10px;
  }
  &:focus:hover {
    svg {
      fill: var(--dark);
    }
  }
  &:hover {
    svg {
      fill: var(--white);
    }
  }
}
.show > .btn-secondary.dropdown-toggle {
  @include btn-hover;
  &:focus {
    @include btn-shadow;
  }
  &:focus:hover {
    svg {
      fill: var(--white);
    }
  }
  svg {
    fill: var(--white);
  }
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
  &:hover {
    background-color: var(--white);
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--dark);
}
