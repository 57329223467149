.opening-hours {
  .box-title {
    margin-bottom: 30px;
  }
  dl {
    margin-bottom: 30px;
    margin-right: 55px;
  }
  dt {
    float: left;
    width: 120px;
  }

  dd {
    margin-left: 130px;
    font-weight: 500;
  }

  dt.today,
  dd.today {
    color: #57d14c;
    font-weight: bold;
  }
}
