// info window
.info-window {
  font-size: 14px;
  line-height: 20px;
  min-height: 80px;
  // width: 180px;
  width: 100%;
  @media (min-width: 1200px) {
    height: 100px;
  }
  &.has-artwork {
    padding-left: 85px;
    figure {
      width: 60px;
      position: absolute;
      left: 12px;
    }
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
    width: 280px;
    padding-right: 10px;
    &.has-artwork {
      padding-left: 123px; //116
      figure {
        width: 100px;
      }
    }
  }
}

.info-window-slider {
  .slick-slider {
    width: 185px;
    margin-bottom: 0;
    overflow-x: hidden;
    @media (min-width: 480px) {
      width: 250px;
    }
    @media (min-width: 576px) {
      width: 300px;
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      width: 200px;
    }
  }

  .slick-prev,
  .slick-next {
    top: auto !important;
    bottom: -15px !important;
  }

  .info-window.has-artwork {
    // padding-left: 123px;
  }

  figure.background-cover {
    background-color: var(--dark);
  }

  .info-window.has-artwork {
    position: relative;
  }
}

// cluster
.cluster:not(:empty) div {
  // background: #f44235;
  // color: #ffffff !important;
  // border-radius: 50%;

  // padding: 5px;
  // display: flex;
  // height: 30px;
  // width: 56px;
  // align-items: center;
  // justify-content: center;
}

// close btn
.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}
