form.search-box {
  position: absolute;
  z-index: 1;
}

.search-box {
  input {
    cursor: pointer;
    float: right;
    width: 50px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent;
    // background-image: url("/img/icons/searchicon.png");
    background-image: url("/img/icons/search.svg");
    // background-position: 10px 7px;
    background-position: 0px 2px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 26px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    text-indent: -9999px;
  }

  input:focus {
    text-indent: inherit;
    border: 0;
    width: 100%;
    padding-left: 40px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
}

@media (max-width: 767px) {
  // full height
  form.search-box {
    top: 0;
    bottom: 0;
    height: 100%;
    right: 0;
    // left: 0;
  }
  .form-group,
  .search-box input {
    height: 100%;
  }
  .search-box input {
    background-position: 0 22px;
  }
}
