.offers-list-map {
  background: #f2f2f2;
  min-height: "250px";
}
.offer-details-page {
  .merchant-details {
    padding-bottom: 24px;
  }
  .top-bg {
    @media (min-width: 768px) {
      padding-top: 50px;
    }
  }
}
.top-bg {
  // padding-top: 70px;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
  .details-row {
    padding-bottom: 40px;
    text-align: left;
    @media (min-width: 768px) {
      // padding-top: 40px;
    }
  }
  .details-row img {
    margin-bottom: 20px;
  }
  .page-title h1,
  .page-title h2 {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 10px;
  }

  .page-title span {
    margin-bottom: 10px;
    color: #d01118;
  }
  .separator {
    margin-top: 10px !important;
    margin-bottom: 30px !important;
  }
}
.merchant-details {
  .merchant-details {
    margin-bottom: 122px;
  }
  .page-title h2 {
    font-size: 23px;
    font-weight: 900;
    margin-bottom: 16px;
  }
  .btn-outline-primary {
    // margin-top: 20px;
    // margin-bottom: 100px;
  }
  .text-right p {
    font-size: 18px;
    font-weight: 900;
  }
  .text-md-left.text-lg-right {
    // margin-top: 15px;
  }
}
.card a {
  text-decoration: none;
  color: black;
}

.offercard-details {
  // margin-top: 14px;
}

// tier benefits
.offer-discount-card .deal-type {
  color: #d01118;
  font-weight: bold;
}

.offer-discount-card .discount-value span.up-to {
  text-transform: uppercase;
  display: block;
  font-weight: 500;
}

.offer-discount-card .discount-value {
  text-align: right;
  font-weight: 600;
}

.offer-discount-card .discount-value .number {
  /* font-weight: 600; */
}

.offer-discount-card {
  background: #ebebed;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.offer-discount-card p {
  margin-bottom: 0;
}

.tier-benefits li {
  float: left;
  width: 50%;
  padding: 8px;
  /* margin-left: -8px; */
  /* margin-right: -8px; */
  @media (min-width: 576px) {
    width: 20%;
  }
}

.tier-benefits {
  margin-bottom: 24px;
}

.tier-title {
  background: #000000;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
}
.tier-title.Bronze {
  background: #cd8032;
}

.tier-title.Silver {
  background: #c0c0c0;
}

.tier-title.Gold {
  background: #daa521;
}

.tier-title.Platinum {
  background: #c4c4c4;
}

.benefits-item {
  background: #dbe0eb;
  height: 80px;
  border-radius: 10px;
  text-align: center;
}

.tier-benefits ul {
  /* width: 100%; */
  display: block;
  margin-left: -8px;
  margin-right: -8px;
}

.benefits-body {
  padding: 10px;
}

.benefits-body .number {
  font-weight: 600;
}

.top-bg .page-title span {
  font-size: 13px;
}
