.accordion {
  .card-body {
    padding: 0;
  }

  .card {
    border: 0;
  }

  .accordion-head {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    .accordion-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .accordion-body {
    padding-bottom: 20px;
  }

  .accordion-head:hover {
    text-decoration: underline;
  }
}
